import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export const ImageSlice = ({ slice }) => {
  return (
    <GatsbyImage
      className="image-slice"
      image={slice.primary.image.gatsbyImageData}
    />
  )
}

export default ImageSlice
